import React from 'react'
import useOnboardingEntities from '../../../hooks/useOnboardingEntities'

import { ContentPreloader } from '@/components/ContentPreloader'
import InfiniteScrollComponent from '@/components/ItemsSections/InfiniteScrollComponent'
import settings from '@/constants/http'
import { useSignupStepsContext } from '@/pages/SignUp/SignupStepsContext'
import { useAppSelector } from '@/store'
import { getUserInfo } from '@/store/auth'
import { useGridStyles } from '../layouts/GridLayout'
import OnboardingItem, { IOnboardingItemProps } from './OnboardingItem'

export type IOnboardingEntityListProps = Omit<IOnboardingItemProps, 'entityId'> & { defaultTake?: number }

const OnboardingEntityList: React.FC<IOnboardingEntityListProps> = ({ children, renderItem, defaultTake, ...params }) => {
	const classes = useGridStyles()
	const userInfo = useAppSelector(getUserInfo)
	const { formData } = useSignupStepsContext()
	const campusId = formData.patches.campusId || userInfo.campusId

	const { ids, isLoading, loadMore, canLoadMore, isUninitialized } = useOnboardingEntities(
		{ ...params, campusId: campusId && campusId > -1 ? campusId : undefined },
		defaultTake ?? settings.SEARCH_LIST_PER_PAGE,
	)

	return (
		<>
			{isLoading ? (
				<ContentPreloader />
			) : (
				<InfiniteScrollComponent
					resetScroll
					internalScroll
					height={450}
					canLoadMore={canLoadMore}
					onLoadMore={loadMore}
					dataLength={ids.length}
					classnames={{ content: classes.cardsContainer }}
					placeholder={ids.length === 0 && !isUninitialized ? 'No Results' : undefined}
				>
					{ids.map((id) => (
						<OnboardingItem {...params} key={id} entityId={id} renderItem={renderItem} />
					))}
				</InfiniteScrollComponent>
			)}
		</>
	)
}

export default OnboardingEntityList
