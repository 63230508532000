import { EditableOnboardingSteps } from '../types/EditableOnboardingSteps.enum'

const allPagesEnabled = []

export const schoolEntityDisabledPages: Record<number, Partial<EditableOnboardingSteps[]>> = {
	// Not handling 1 (PSU)
	4: [EditableOnboardingSteps.AUTO_FOLLOW_ORGANIZATIONS],
	5: allPagesEnabled,
	6: [EditableOnboardingSteps.FOLLOW_ACADEMIC_ORGS, EditableOnboardingSteps.AUTO_CONNECT_USERS],
	7: [
		EditableOnboardingSteps.OPPORTUNITIES,
		EditableOnboardingSteps.FOLLOW_ACADEMIC_ORGS,
		EditableOnboardingSteps.CONNECTIONS,
		EditableOnboardingSteps.AUTO_CONNECT_USERS,
		EditableOnboardingSteps.AUTO_FOLLOW_ORGANIZATIONS,
	],
	9: [EditableOnboardingSteps.FOLLOW_ACADEMIC_ORGS, EditableOnboardingSteps.AUTO_CONNECT_USERS],
	10: [EditableOnboardingSteps.JOIN_GROUPS, EditableOnboardingSteps.AUTO_CONNECT_USERS],
	11: allPagesEnabled,
	12: allPagesEnabled,
	13: [EditableOnboardingSteps.CONNECTIONS, EditableOnboardingSteps.OPPORTUNITIES, EditableOnboardingSteps.AUTO_CONNECT_USERS],
	15: [EditableOnboardingSteps.FOLLOW_ACADEMIC_ORGS],
	16: [EditableOnboardingSteps.FOLLOW_ACADEMIC_ORGS, EditableOnboardingSteps.CONNECTIONS, EditableOnboardingSteps.OPPORTUNITIES],
}
