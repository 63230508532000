import settings from '@/constants/http'
import { NotificationPages, UserNotification } from '@/store/auth/types'
import { isEqual } from 'lodash'
import { objectToSearchString } from 'use-query-params'
import { baseApi } from '../general/baseApi'
import {
	GetScheduledNotificationsDTO,
	NotificationStatus,
	PatchNotificationDTO,
	PostNotificationDTO,
	TransformedGetScheduledNotifications,
} from './types'

export interface UserNotificationsDto {
	notifications: UserNotification[]
	total: number
	unviewed: number
	canLoadMore: boolean
}

export interface UserNotificationsQueryParams {
	offset?: number
	userId: number
	perPage?: number
	type: NotificationPages
}

const TAGS: Record<NotificationPages, string> = {
	new: 'NewNotification',
	past: 'PastNotification',
	all: 'AllNotification',
	scheduled: 'ScheduledNotification',
} as const

const BASE_PATH = `/notifications`
const USER_BASE_PATH = `${BASE_PATH}/user`
const SCHEDULED_BASE_PATH = `${BASE_PATH}/scheduled`

export const notificationsApi = baseApi
	.enhanceEndpoints({
		addTagTypes: [],
	})
	.injectEndpoints({
		endpoints: (builder) => ({
			getUserNotifications: builder.query<UserNotificationsDto, UserNotificationsQueryParams>({
				query: ({ userId, offset = 0, perPage = settings.SEARCH_LIST_PER_PAGE, type }) => ({
					url: `${USER_BASE_PATH}/${userId}`,
					params: {
						offset: offset > 0 ? offset : undefined,
						perPage,
						viewed: type === 'all' ? undefined : !(type === 'new'),
					},
				}),
				serializeQueryArgs: ({ endpointName, queryArgs }) => {
					const serializedKey = `${endpointName}-${queryArgs.userId}-${queryArgs.type}`
					return serializedKey
				},
				merge: (currentCache, { total, unviewed, notifications }, { arg: { offset } }) => {
					if (offset && offset > 0) {
						currentCache.notifications.push(...notifications)
						currentCache.total = total
						currentCache.unviewed = unviewed
					} else {
						currentCache.notifications = notifications
						currentCache.total = total
						currentCache.unviewed = unviewed
					}
				},

				forceRefetch({ currentArg, previousArg }) {
					return !isEqual(currentArg, previousArg)
				},
				providesTags: (result, error, { type }) => [TAGS[type]],
			}),
			markAllRead: builder.mutation<undefined, { userId: number }>({
				query: ({ userId }) => ({
					url: `${USER_BASE_PATH}/${userId}/view-all`,
					method: 'PATCH',
				}),
				invalidatesTags: [TAGS.all, TAGS.past, TAGS.new],
			}),
			patchUserNotifications: builder.mutation<undefined, { userId: number; viewedIds: number[]; type: NotificationPages }>({
				query: ({ userId, viewedIds }) => ({
					url: `${USER_BASE_PATH}/${userId}`,
					method: 'PATCH',
					body: {
						viewedIds,
					},
				}),
				onQueryStarted: async ({ userId, viewedIds, type }, { dispatch, queryFulfilled }) => {
					const patchResult = dispatch(
						notificationsApi.util.updateQueryData('getUserNotifications', { userId, type }, (draft) => {
							if (type === 'new') {
								draft.unviewed -= viewedIds.length
								draft.total -= viewedIds.length
							}
							draft.notifications.forEach((notification) => {
								if (viewedIds.includes(notification.id)) {
									notification.receiver.viewed = true
								}
							})
						}),
					)

					const patchAllNotifications = dispatch(
						notificationsApi.util.updateQueryData('getUserNotifications', { userId, type: 'all' }, (draft) => {
							if (draft.unviewed - viewedIds.length >= 0) {
								draft.unviewed -= viewedIds.length
							}
							draft.notifications.forEach((notification) => {
								if (viewedIds.includes(notification.id)) {
									notification.receiver.viewed = true
								}
							})
						}),
					)

					try {
						await queryFulfilled
					} catch {
						patchResult.undo()
						patchAllNotifications.undo()
					}
				},
			}),
			getScheduledNotifications: builder.query<GetScheduledNotificationsDTO, any>({
				query: (params) => ({
					url: `${SCHEDULED_BASE_PATH}`,
					params,
				}),
				transformResponse: (response: GetScheduledNotificationsDTO): TransformedGetScheduledNotifications => {
					const items = response.items.map((item) => ({ ...item, isEditable: item.status === NotificationStatus.PENDING }))
					return { ...response, items }
				},
				providesTags: (result, error, arg) =>
					result ? [...result.items.map(({ id }) => ({ type: TAGS.scheduled, id })), TAGS.scheduled] : [TAGS.scheduled],
			}),
			postScheduledNotifications: builder.mutation<undefined, Omit<PostNotificationDTO, 'audience'>>({
				query: (notification) => {
					return {
						url: `${SCHEDULED_BASE_PATH}`,
						method: 'POST',
						body: notification,
					}
				},
				invalidatesTags: [TAGS.scheduled],
			}),
			deleteScheduledNotifications: builder.mutation<undefined, { notificationIds: string[] }>({
				query: ({ notificationIds }) => ({
					url: `${SCHEDULED_BASE_PATH}?${objectToSearchString({ ids: notificationIds })}`,
					method: 'DELETE',
				}),
				invalidatesTags: [TAGS.scheduled],
			}),
			patchScheduledNotifications: builder.mutation<undefined, PatchNotificationDTO>({
				query: (notification) => {
					return {
						url: `${SCHEDULED_BASE_PATH}?${objectToSearchString(notification)}`,
						method: 'PATCH',
					}
				},
				invalidatesTags: (result, error, arg) => [{ type: TAGS.scheduled, id: arg.id }, TAGS.scheduled],
			}),
		}),
	})

export const {
	useMarkAllReadMutation,
	useGetUserNotificationsQuery,
	usePatchUserNotificationsMutation,
	useGetScheduledNotificationsQuery,
	useDeleteScheduledNotificationsMutation,
	usePostScheduledNotificationsMutation,
	usePatchScheduledNotificationsMutation,
} = notificationsApi
